import React from "react";
import "./navigation.css";

import { ReactComponent as Right } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as Home } from "../../assets/icons/home-line.svg";


export default function Navigation({type, city}) {
  return (
    <div className="navigation">
        <Home />
        <Right />
        <span>{type}</span>
        <Right />
        <span>{city}</span>
    </div>
  );
}