import React, { useState } from 'react'
import './filters.css'

import { ReactComponent as Down } from '../../assets/icons/chevron-down2.svg'
import { ReactComponent as Search } from '../../assets/icons/search.svg'
import { ReactComponent as Advanced } from '../../assets/icons/filters.svg'

const Filters = () => {
    const [type, setType] = useState(null)
  return (
		<div className='filters-panel'>
			<div className='filters-btns'>
				<button className='filters-btn active'>Վաճառք</button>
				<button className='filters-btn'>Վարձակալություն</button>
			</div>
			<div className='filters'>
				<div>
					<p className='custom-select-header1'>Գույքի տեսակը</p>
					<div className='custom-select'>
						<span>Apartment</span>
						<Down />
					</div>
				</div>
				<div>
					<p className='custom-select-header1'>Շրջան</p>
					<div className='custom-select'>
						<span>Yerevan</span>
						<Down />
					</div>
				</div>
				<div>
					<p className='custom-select-header1'>Արժեք $</p>
					<div className='prices'>
						<input
							type='number'
							min='1000'
							value='1000'
							className='price-input'
						/>
						<div className='line' id='line'></div>
						<input
							type='number'
							min='2000'
							value='2000'
							className='price-input'
						/>
					</div>
				</div>
				<div className='advanced-buttons'>
					<button className='open-advanced'>
						<Advanced />
						Ավելին
					</button>
				</div>
				<button className='btn search'>
					<Search />
					Որոնում
				</button>
			</div>
		</div>
	)
}

export default Filters