import React from 'react'
import Filters from '../filters/Filters'
import './wrapper.css'

const Wrapper = () => {
  return (
		<div className='wrapper'>
			<div className='wrapper_about_panel'>
				<p className='wrapper_about'>Easy. Fast. Simple.</p>
				<span className='wrapper_about_span'>The <span style={{color: "var(--main-color)"}}>easy</span> way to your dream home.</span>
				<span className='wrapper_about_span_small'>Searching for real estate has never been this simple.</span>
			</div>
			<Filters />
		</div>
	)
}

export default Wrapper