import React from 'react'
import './header.css'
import Logo from '../../assets/Logo.png'
import LogoLight from '../../assets/Logo_light.png'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { ReactComponent as Language } from '../../assets/icons/language.svg'
import { ReactComponent as LanguageLIght } from '../../assets/icons/language_light.svg'
import { ReactComponent as Phone } from '../../assets/icons/phone_call.svg'
import { ReactComponent as LanguageArrow } from '../../assets/icons/chevron-down2.svg'

const Header = ({onOpenMenu}) => {

	const location = useLocation()

  return (
		<header
			style={
				location.pathname === '/'
					? { position: 'absolute', borderBottom: 'none', background: 'none' }
					: null
			}
		>
			<div className='container' style={{ display: 'flex' }}>
				<div style={{ display: 'flex' }}>
					<a href='/'>
						<img
							src={location.pathname === '/' ? LogoLight : Logo}
							alt='logo'
						/>
					</a>
					<div className='header-links'>
						{location.pathname === '/' ? (
							<>
								<Link className='text-light' to={'/'}>
									Գլխավոր
								</Link>
								<Link className='text-light' to={'/sell'}>
									Վաճառք
								</Link>
								<Link className='text-light' to={'/rent'}>
									Վարձակալություն
								</Link>
								<Link className='text-light' to>
									Կապ մեզ հետ
								</Link>
							</>
						) : (
							<>
								<Link className='text-dark' to={'/'}>
									Գլխավոր
								</Link>
								<Link className='text-dark' to={'/sell'}>Վաճառք</Link>
								<Link className='text-dark' to={'/rent'}>Վարձակալություն</Link>
								<Link className='text-dark'>Կապ մեզ հետ</Link>
							</>
						)}
					</div>
				</div>
				<div>
					<div className='lang'>
						{location.pathname === '/' ? <LanguageLIght /> : <Language />}
						<span>ENG</span>
						<LanguageArrow />
					</div>
					<a href='tel:+37455600096'>
						<button className='call btn'>
							<Phone />
							Կապ մեզ հետ
						</button>
					</a>
				</div>
				<svg
					onClick={onOpenMenu}
					id='burger-menu-icon'
					xmlns='http://www.w3.org/2000/svg'
					width='40'
					height='40'
					fill={location.pathname === '/' ? '#fff' : '#333'}
					class='bi bi-list'
					viewBox='0 0 16 16'
				>
					<path
						fill-rule='evenodd'
						d='M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5'
					/>
				</svg>
			</div>
		</header>
	)
}

export default Header