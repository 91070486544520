import React from 'react'
import Wrapper from '../../components/wrapper/Wrapper'
import ForSale from '../../components/for-sale/ForSale'
import ForRent from '../../components/for-rent/ForRent'
import WhyChooseUs from '../../components/why-choose-us/WhyChooseUs'
import ContactUs from '../../components/contact-us/ContactUs'

const Home = () => {
  return (
    <div>
        <Wrapper />
        <ForSale />
        <ForRent />
        <WhyChooseUs />
        <ContactUs />
    </div>
  )
}

export default Home