import React from 'react'
import './item-params.css'

import { ReactComponent as Stairs } from '../../assets/icons/floor.svg'
import { ReactComponent as Bed } from '../../assets/icons/single.svg'
import { ReactComponent as Bath } from '../../assets/icons/bathtub.svg'
import { ReactComponent as Square } from '../../assets/icons/square.svg'
import { ReactComponent as Phone } from '../../assets/icons/phone_call.svg'

const ItemParams = () => {
  return (
		<div className='item-params'>
			<div className='home-info'>
				<p className='appartment-type'>Modern Apartment</p>
				<div className='apt-type-amount'>
					<span className='amount'>$2,000</span>
					<span> / </span>
					<span>ամիս</span>
				</div>
				<p className='item-param-street'>Arshakunyac Ave, Center, Yerevan</p>
			</div>
			<div className='home-params'>
				<div className='home-params-items'>
					<Stairs />
					<span>Հարկ:</span>
					<span>9</span>
				</div>
				<div className='home-params-items'>
					<Bed />
					<span>Սենյակ:</span>
					<span>3</span>
				</div>
				<div className='home-params-items'>
					<Bath />
					<span>Սանհանգույց:</span>
					<span>2</span>
				</div>
				<div className='home-params-items'>
					<Square />
					<span>Մակերես:</span>
					<span>120 ք.մ</span>
				</div>
				<div className='call-button btn'>
					<Phone />
					Կապ մեզ հետ
				</div>
			</div>
		</div>
	)
}

export default ItemParams   