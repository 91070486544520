import React, { useEffect } from 'react'
import Img from '../../assets/item.png'
import './item.css'

import { ReactComponent as Check } from '../../assets/icons/check.svg'
import ItemParams from '../../components/item-params/ItemParams'

const Item = () => {
	  useEffect(() => {
			window.scrollTo(0, 0)
		}, [])
  return (
		<div className='container'>
			<div className='item-images'>
				<img src={Img} width={'100%'} alt='img'/>
			</div>
			<div className='item-page-data'>
				<div className='item-info-section'>
					<div className='item-info-section-top'>
						<div>
							<p className='item-headers'>Նկարագրություն</p>
							<span className='item-info-text'>
								Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
								suspendisse morbi eleifend faucibus eget vestibulum felis.
								Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam.
								Mauris posuere vulputate arcu amet, vitae nisi, tellus
								tincidunt. At feugiat sapien varius id.
							</span>
						</div>
						<div className='amenities'>
							<p className='item-headers'>Առավելություններ</p>
							<span className='item-info-text'>
								MMi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
								suspendisse morbi eleifend faucibus eget vestibulum felis.
								Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam.
								Mauris posuere vulputate arcu amet, vitae nisi, tellus
								tincidunt. At feugiat sapien varius id.
							</span>
						</div>
					</div>
					<div className='all-checks'>
						<div>
							<Check />
							<span>Heating</span>
						</div>
						<div>
							<Check />
							<span>Heating</span>
						</div>
						<div>
							<Check />
							<span>Heating</span>
						</div>
						<div>
							<Check />
							<span>Heating</span>
						</div>
						<div>
							<Check />
							<span>Heating</span>
						</div>
					</div>
					<div className='location'>
						<p className='item-headers'>Հասցե</p>
						<span className='item-info-text'>
							Arshakunyats Ave, Center, Yerevan
						</span>
						<iframe
							src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.315939723092!2d44.52786588343319!3d40.208359652166344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa333ff1194ed%3A0xeebb8deec19b5dc3!2sB%20Plaza!5e0!3m2!1sru!2sam!4v1740622840651!5m2!1sru!2sam'
							width='100%'
							height='292'
							allowfullscreen=''
							loading='lazy'
							title='map'
							referrerpolicy='no-referrer-when-downgrade'
						></iframe>
					</div>
				</div>
                <ItemParams />
			</div>
		</div>
	)
}

export default Item