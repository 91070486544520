import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import Logo from '../../assets/Logo.png'

import { ReactComponent as Language } from '../../assets/icons/language.svg'
import { ReactComponent as Phone } from '../../assets/icons/phone_call.svg'
import { ReactComponent as Tg } from '../../assets/icons/Social icon.svg'	
import { ReactComponent as Inst } from '../../assets/icons/Social icon-1.svg'
import { ReactComponent as Fb } from '../../assets/icons/Social icon-2.svg'
import { ReactComponent as Langarrowdark } from '../../assets/icons/chevron-down2.svg'

const Footer = () => {
  return (
		<footer className='container'>
			<div className='footer-content'>
				<div className='footer-logo'>
					<img src={Logo} alt='logo' />
					<div className='footer-links'>
						<Link to={'/'}>Գլխավոր</Link>
						<Link to={'/sell'}>Վաճառք</Link>
						<Link to={'/rent'}>Վարձակալություն</Link>
						<Link>Կապ մեզ հետ</Link>
					</div>
				</div>
				<div className='footer-contact'>
					<div className='lang-footer'>
						<Language />
						<span>ENG</span>
						<Langarrowdark />
					</div>
					<a href='tel:+37455600096'>
						<button className='call btn'>
							<Phone />
							Կապ մեզ հետ
						</button>
					</a>
				</div>
			</div>
			<div className='footer-line'></div>
			<div className='footer-bottom'>
				<p id='footer-text'>© 2025 Homely. All rights reserved.</p>
				<div className='footer-social-links'>
					<a href='https://t.me/realestateagencyhomely'>
						<Tg class='social-media' />
					</a>
					<a href='https://www.instagram.com/homely.am?igsh=MmNrdnllZ2s1c3Zp'>
						<Inst className='social-media' />
					</a>
					<a href='https://www.facebook.com/share/1DMbh98gkR/?mibextid=qi2Omg'>
						<Fb className='social-media' />
					</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer