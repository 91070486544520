import React from 'react'
import './burger-menu.css'
import Logo from '../../assets/Logo.png'
import { Link } from 'react-router-dom'
import {ReactComponent as Language} from '../../assets/icons/language.svg'
import { ReactComponent as Phone } from '../../assets/icons/phone_call.svg'

const BurgerMenu = ({onClose, isActive}) => {
  return (
		<div className={isActive ? 'burger-menu active' : 'burger-menu'}>
			<div className='burger-menu-header'>
				<img src={Logo} alt='' />
				<svg
					onClick={onClose}
					xmlns='http://www.w3.org/2000/svg'
					width='40'
					height='40'
					fill='#475467'
					class='bi bi-x'
					viewBox='0 0 16 16'
				>
					<path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708' />
				</svg>
			</div>
			<div className='burger-menu-links' onClick={onClose}>
				<Link>
					<Language /> ENG
				</Link>
				<Link to={'/'}>Գլխավոր</Link>
				<Link to={'/sell'}>Վաճառք</Link>
				<Link to={'/rent'}>Վարձակալություն</Link>
				<Link>Կապ մեզ հետ</Link>
			</div>
			<div className='burger-menu-btn'>
				<button className='btn'>
					<Phone /> Կապ մեզ հետ
				</button>
			</div>
		</div>
	)
}

export default BurgerMenu