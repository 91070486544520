import React from 'react'
import './why.css'

import { ReactComponent as Db } from '../../assets/icons/Featured icon.svg'
import { ReactComponent as Date } from '../../assets/icons/Featured icon-1.svg'
import { ReactComponent as Info } from '../../assets/icons/Featured icon-2.svg'
import { ReactComponent as Support } from '../../assets/icons/Featured icon-3.svg'

const WhyChooseUs = () => {
  return (
		<div className='container why'>
			<h2 className='block-header'>Ինչու ընտրել մեզ</h2>
			<p className='about-us'>
				Մենք առաջարկում ենք յուրահատուկ լուծումներ ՝ հարմարեցված մեր
				հաճախորդների կարիքներին ՝ անհատական մոտեցմամբ և գերազանց արդյունքների
				երաշխիքով:
			</p>
			<div className='info-boxes'>
				<div className='info-box'>
					<Db />
					<div>
						<p className='box-header'>Ընդարձակ Տվյալների Բազա</p>
						<span className='box-text'>
							Հարյուրավոր անշարժ գույքի գնման և վարձակալության
							հայտարարություններ մեկ տեղում
						</span>
					</div>
				</div>
				<div className='info-box'>
					<Date />
					<div>
						<p className='box-header'>Ընթացիկ հայտարարություններ</p>
						<span className='box-text'>
							Միայն թարմ առաջարկներ սեփականատերերից և վստահելի
							գործակալություններից:
						</span>
					</div>
				</div>
				<div className='info-box'>
					<Info />
					<div>
						<p className='box-header'>Մանրամասն տեղեկություններ</p>
						<span className='box-text'>
							Իրական լուսանկարներ, ճշգրիտ նկարագրություններ և գույքի
							մանրամասներ:
						</span>
					</div>
				</div>
				<div className='info-box'>
					<Support />
					<div>
						<p className='box-header'>Կապ հաճախորդի հետ</p>
						<span className='box-text'>
							Մեր մենեջերը կպատասխանի Ձեր բոլոր հարցերին
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default WhyChooseUs