import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Home from './pages/home/Home';
import Footer from './components/footer/Footer';
import Filters from './pages/filters/Filters';
import Item from './pages/item/Item';
import Rent from './pages/rent/Rent';
import BurgerMenu from './components/burger-menu/BurgerMenu';
import { useState } from 'react';

function App() {
  const [openedMenu, setOpenedMenu] = useState(false)
  const handleOpenMenu = () => setOpenedMenu(!openedMenu)
  return (
		<Router>
      <BurgerMenu onClose={handleOpenMenu} isActive={openedMenu}/>
			<Header onOpenMenu={handleOpenMenu}/>
      <Routes>
        <Route path='/*' element={<Home />} />
        <Route path='/sell' element={<Filters />} />
        <Route path='/item' element={<Item />} />
        <Route path='/rent' element={<Rent />} />
      </Routes>
      <Footer />
		</Router>
	)
}

export default App;
