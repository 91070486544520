import React from 'react'
import './district.css'

export const District = () => {
  return (
    <div className="district1">
        <div className="checks">
            <div>
                <input type="checkbox" name="dis" id="Center" />
                <span>Center</span>
            </div>
            <div>
                <input type="checkbox" name="dis" id="Arabkir" />
                <span>Arabkir</span>
            </div>
            <div>
                <input type="checkbox" name="dis" id="Davtashen" />
                <span>Davtashen</span>
            </div>
            <div>
                <input type="checkbox" name="dis" id="Achapnyak" />
                <span>Achapnyak</span>
            </div>
            <div>
                <input type="checkbox" name="dis" id="Avan" />
                <span>Avan</span>
            </div>
            <div>
                <input type="checkbox" name="dis" id="Nor Nork" />
                <span>Nor Nork</span>
            </div>
        </div>
        <div className="buttons">
            <button className='reset btn'>Reset</button>
            <button className='apply btn'>Apply</button>
        </div>
    </div>
  )
}