import React from 'react'
import './card.css'
import Home from '../../assets/home.png'

import { ReactComponent as Floor} from '../../assets/icons/floor.svg'
import { ReactComponent as Single } from '../../assets/icons/single.svg'
import { ReactComponent as Bathtub } from '../../assets/icons/bathtub.svg'
import { ReactComponent as Square } from '../../assets/icons/square.svg'

import { Link } from 'react-router-dom'

const Card = () => {
  return (
		<Link to={'/item'}>
			<div className='card'>
				<div className='card-header'>
					<img alt="img" src={"https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg"} height={'240px'} width={'100%'} />
					<div className="main-dots">
						<div></div><div></div><div></div><div></div><div></div>
					</div>
				</div>
				<div className='card-footer'>
					<div className='card-footer-top'>
						<span className='card-price'>
							$2,000 <span className='month-text'>/ ամիս</span>
						</span>
						<span className='card-id'>ID 12322</span>
					</div>
					<p className='card-apartment'>Modern apartment</p>
					<p className='card-address'>Arshakunyats Ave, Center, Yerevan</p>
					<div className='card-line'></div>
					<div className='card-footer-bottom'>
						<div className='card-info-box'>
							<Floor />
							<span>9</span>
						</div>
						<div className='card-info-box'>
							<Single />
							<span>3</span>
						</div>
						<div className='card-info-box'>
							<Bathtub />
							<span>2</span>
						</div>
						<div className='card-info-box squaremeter'>
							<Square />
							<span>159 ք. մ</span>
						</div>
					</div>
				</div>
			</div>
		</Link>
	)
}

export default Card