import React from "react";
import "./rent.css";
import Card from "../../components/card/Card";
import Navigation from "../../components/navigation/Navigation";
import { ReactComponent as Down } from "../../assets/icons/chevron-down2.svg";
import { ReactComponent as Filter } from "../../assets/icons/filters.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as SearchDark } from "../../assets/icons/search2.svg";
import { ReactComponent as Close } from '../../assets/icons/close.svg'
import { ReactComponent as Rectangle } from '../../assets/icons/Rectangle.svg'

export default function Rent() {
    return (
			<>
				<div className='container'>
					<div className='search-filter-mobile'>
						<div className='search-mob mob'>
							<SearchDark />
							<span>Yerevan</span>
						</div>
						<div className='filter-mob mob'>
							<Filter />
						</div>
					</div>
					<div className='search-bar'>
						<div className='search-mob mob'>
							<SearchDark />
							<span>Building, Area or Project name</span>
						</div>
						<div className='down-mob mob'>
							<span>Apartment</span>
							<Down />
						</div>
						<div className='down-mob mob'>
							<span>Most Recent</span>
							<Down />
						</div>
						<div className='filter-mob'>
							<Filter />
							<span>Ավելին</span>
						</div>
						<button className='srch-btn btn search'>
							<Search />
							Որոնում
						</button>
					</div>
				</div>
				<div
					className='container filter-container'
					style={{ display: 'flex', gap: '20px' }}
				>
					<div className='navigation-and-filters'>
						<Navigation
							style={{ margin: '32px 0' }}
							city={'Երևան'}
							type={'Վարձակալություն'}
						/>
						<div className='filters-bar'>
							<div className='sort-filter'>
								<span>Դասակարգում և ֆիլտրեր</span>
								<Close
									style={{ padding: '8px', width: '40px', height: '40px' }}
								/>
							</div>
							<div className='filter-params'>
								<div className='transaction-type'>
									<p>Տեսակ</p>
									<div>
										<input type='radio' name='tt' id='for-sale' />
										<span>Վաճառք</span>
									</div>
									<div>
										<input type='radio' name='tt' id='for-rent' />
										<span>Վարձակալություն</span>
									</div>
								</div>
								<div className='property-type'>
									<p className='custom-select-header'>Անշարժ գույքի տեսակ</p>
									<div className='custom-select'>
										<span>Բնակարան</span>
										<Down />
									</div>
									<input type='checkbox' name='newbuilding' id='newbuilding' />
									<span>Նորակառույց</span>
								</div>
								<div className='area select'>
									<p>Տարածաշրջան</p>
									<div className='select-several'>
										<div className='selected-several' id='area'>
											<span>Երևան</span>
											<Close className='selected-close' />
										</div>
										<div className='selected-several' id='area'>
											<span>Արարատ</span>
											<Close className='selected-close' />
										</div>
									</div>
								</div>
								<div className='district select'>
									<p>Թաղամաս</p>
									<div className='select-several'>
										<div className='selected-several' id='area'>
											<span>Կենտրոն</span>
											<Close className='selected-close' />
										</div>
										<div className='selected-several' id='area'>
											<span>Արաբկիր</span>
											<Close className='selected-close' />
										</div>
									</div>
								</div>
								<div className='bedrooms numbers'>
									<p>Ննջասենյակներ</p>
									<div className='number-select'>
										<p>Ստուդիա</p>
										<p>1</p>
										<p>2</p>
										<p>3</p>
										<p>4+</p>
									</div>
								</div>
								<div className='bathrooms numbers'>
									<p>Լոգասենյակներ</p>
									<div className='number-select'>
										<p>1</p>
										<p>2</p>
										<p>3</p>
										<p>4+</p>
									</div>
								</div>
								<div className='total-area range' id='area'>
									<p>Ընդհանուր մակերես</p>
									<div className='minmax-togheter'>
										<div className='minmax'>
											<input
												type='text'
												name='minmax'
												id='min'
												value={'50 քմ'}
											/>
										</div>
										<Rectangle className='rectangle-line' />
										<div className='minmax'>
											<input
												type='text'
												name='minmax'
												id='max'
												value={'80 քմ'}
											/>
										</div>
									</div>
								</div>
								<div className='price-range range' id='area'>
									<p>Գնային միջակայք</p>
									<div className='minmax-togheter'>
										<div className='minmax'>
											<input
												type='text'
												name='minmax'
												id='min'
												value={'$ 1000'}
											/>
										</div>
										<Rectangle className='rectangle-line' />
										<div className='minmax'>
											<input
												type='text'
												name='minmax'
												id='max'
												value={'$ 2000'}
											/>
										</div>
									</div>
								</div>
								<div className='building-type checkbox' id='area'>
									<p>Շենքի տեսակ</p>
									<div className='selections'>
										<div className='selections-item'>
											<input type='checkbox' name='btype' id='monolith' />
											<span>Մոնոլիտ</span>
										</div>
										<div className='selections-item'>
											<input type='checkbox' name='btype' id='stone' />
											<span>Քարե</span>
										</div>
										<div className='selections-item'>
											<input type='checkbox' name='btype' id='Panel' />
											<span>Պանելային</span>
										</div>
										<div className='selections-item'>
											<input type='checkbox' name='btype' id='Other' />
											<span>Այլ</span>
										</div>
									</div>
								</div>
								<div className='floor' id='area'>
									<p>Հարկ</p>
									<div className='custom-select'>
										<span>Ցանկացած հարկ</span>
										<Down />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='prop-text'>
						<p className='prop-text-p'>Վարձով անշարժ գույք Երևանում</p>
						<div className='cards'>
							<Card />
							<Card />
							<Card />
							<Card />
							<Card />
							<Card />
							<Card />
							<Card />
							<Card />
						</div>
					</div>
				</div>
			</>
		)
}