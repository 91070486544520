import React from 'react'
import Card from '../card/Card'

const ForRent = () => {
  return (
		<div className='aprt-section container'>
			<h2 className='section-header'>Վարձով բնակարաններ</h2>
			<div className='cards'>
				<Card />
				<Card />
				<Card />
				<Card />
				<Card />
				<Card />
			</div>
		</div>
	)
}

export default ForRent