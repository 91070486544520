import React from 'react'
import './contact-us.css'

import { ReactComponent as Phone } from '../../assets/icons/phone-support.svg'
import { ReactComponent as Email } from '../../assets/icons/email-support.svg'
import { ReactComponent as Location } from '../../assets/icons/location-support.svg'

const ContactUs = () => {
  return (
		<div className='container contact-us'>
			<p className='section-header' style={{ marginBottom: '20px' }}>
				Կապ մեզ հետ
			</p>
			<p className='section-header-small'>
				Մեր թիմը սիրով կլսի Ձեր առաջարկները
			</p>
			<div className='contact-us-section'>
				<iframe
					title='map'
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.315939723092!2d44.52786588343319!3d40.208359652166344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406aa333ff1194ed%3A0xeebb8deec19b5dc3!2sB%20Plaza!5e0!3m2!1sru!2sam!4v1740622840651!5m2!1sru!2sam'
					width='800'
					height='450'
					allowfullscreen=''
					loading='lazy'
					referrerpolicy='no-referrer-when-downgrade'
				></iframe>
				<div className='support-cards'>
					<div className='support-card'>
						<Email />
						<div>
							<p className='card-header'>Էլ. հասցե</p>
							{/* <p className='card-text'>Our friendly team is here to help.</p> */}
							<p className='example'>info@homely.am</p>
						</div>
					</div>
					<div className='support-card'>
						<Location />
						<div>
							<p className='card-header'>Հասցե</p>
							{/* <p className='card-text'>Come say hello at our office HQ.</p> */}
							<p className='example'>Ազատության պող., 24/15 շենք</p>
						</div>
					</div>
					<div className='support-card'>
						<Phone />
						<div>
							<p className='card-header'>Հեռ. համար</p>
							{/* <p className='card-text'>Mon-Fri from 9am to 6pm.</p> */}
							<p className='example'>+ 374 55 60 00 96</p>
							<p className='example'>+ 374 95 06 06 90</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ContactUs